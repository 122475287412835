.LoginForm {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 100vh;
    
    background-image: url('../assets/dam-bg.jpg');
    background-size: cover;
}

.project-description{
    flex-basis: 30%;
    min-height: 100vh;
    padding: 65px 1em 0;
    background-color: white;
    box-shadow: 5px 5px 10px rgb(87, 87, 87);
}

.project-login {
    flex-basis: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    .LoginForm {
        flex-direction: column-reverse;
    }
    .project-description{
        flex-basis: 100%;
    }
    .project-login {
        min-height: 70vh;
        flex-basis: 100%;
    }
  }

.LoginBox{ 
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.errorMessage{
    color: red;
    background-color: rgba(202, 10, 10, 0.267);
    padding: 1em 2em ;
    margin-bottom: 2em;
    border-radius: 5px;
}

th, td{
    border: 1px solid black;
    padding: 0.5em;
}

.hintBox{
    display: none;
}